var mobileMenuState = "up";
var mobileMenu = document.getElementById('mobile-menu');

document.getElementById('mobile-toggle').onclick = function mobileMenuStuff() {
  if (mobileMenuState === "up") {
    animation.setDirection(1);
    animation.setSpeed(2);
    animation.play();
    mobileMenuState = "down";
    mobileMenu.classList.add('active');
  } else {
    animation.setDirection(-1);
    animation.play();
    mobileMenuState = "up";
    mobileMenu.classList.remove('active');
  }
};
